<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <!-- <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item
        v-else
        :key="`item-${i}`"
        :item="item"
      />
    </template> -->

    <template>
      <default-list-item
        :item="{
          title: 'Area',
          icon: 'mdi-map-marker',
          to: '/area',
        }"
      />
      <default-list-item
        :item="{
          title: 'Appointment Type',
          icon: require('@/assets/settings_icon.png'),
          to: '/appointmentType',
        }"
      />
      <div  v-show="currentUserGroupOrder <= 200">
<!--      v-if="!hideAdminMenu"-->
<!--      <default-list-item-->
<!--        :item="{-->
<!--          title: 'Admin',-->
<!--          icon: 'mdi-shield-crown',-->
<!--          to: '/admin',-->
<!--        }"-->
<!--      />-->
      </div>
    </template>
  </v-list>
</template>

<script>
import axios from "axios";

export default {
  name: "DefaultList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    currentUserGroupOrder: localStorage.getItem("groupOrder"),
    hideAdminMenu: true
  }),
  components: {
    // DefaultListGroup: () => import("./ListGroup"),
    DefaultListItem: () => import("./ListItem"),
  },
  methods: {
      async checkViewAdminMenu() {
        await axios.get(`auth/current-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }).then(response => {
          let isSuperAdmin = response?.data?.IsSuperAdmin;
          let secureAcl = response?.data?.ACLs.some((item) => item.UserGroup.Order <= 200);
          this.hideAdminMenu = !(isSuperAdmin || secureAcl);
        })
      }
  },
  async mounted() {
    await this.checkViewAdminMenu()
  }
};
</script>
