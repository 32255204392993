var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',_vm._g(_vm._b({attrs:{"expand":"","nav":""}},'v-list',_vm.$attrs,false),_vm.$listeners),[[_c('default-list-item',{attrs:{"item":{
          title: 'Area',
          icon: 'mdi-map-marker',
          to: '/area',
        }}}),_c('default-list-item',{attrs:{"item":{
          title: 'Appointment Type',
          icon: require('@/assets/settings_icon.png'),
          to: '/appointmentType',
        }}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentUserGroupOrder <= 200),expression:"currentUserGroupOrder <= 200"}]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }